<template>
  <v-container>
    <navbar title="انتخاب آدرس" route = '/basket'/>
    <error-alert/>
    <div class="pt-15 section d-flex flex-column justify-center" style="padding-bottom: 90px">
      <receiver v-if="receivers"/>
      <v-skeleton-loader
      v-if="receivers.length < 1"
      v-bind="attrs" max-width="354" height="186"
      type="list-item-three-line, list-item-two-line" 
      ></v-skeleton-loader>
        <div class="mt-4">
          <v-icon class="primaryText--text">mdi-plus</v-icon>
          <span class="primaryText--text mr-1 font" @click="addAddress">افزودن آدرس جدید</span>
        </div>
    </div>
     <div>
        <bfooter title="تعیین زمان ارسال"/>
     </div>
  </v-container>
</template>
<script>
import receiver from './components/cards/recieverDetail'
import navbar from '@/components/navbar/appBar'
import bfooter from './components/buttons/basketFooterBtn'
import router from '@/router'
import { basketResiver, viewbasket_products } from './models/Basket'
import ErrorAlert from '@/components/alerts/error.alert.vue'
import { mapState } from 'vuex'
export default {
  name: 'reciever',
  components: {
    receiver,
    navbar,
    bfooter,
    ErrorAlert,
  },
  data: () => ({
    attrs: {
      boilerplate: false,
      elevation: 0,
    },
  }),
  created () {
    basketResiver()
    viewbasket_products()
  },
  computed: {
    ...mapState({
      receivers: state => state.address.UserAddress,
    })
  },
  methods: {
    addAddress() {
        router.push('/add_reciever')
    }
  }
}
</script>